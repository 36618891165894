<template>
  <CasesTable></CasesTable>
</template>

<script>
import CasesTable from "./CasesTable.vue";
export default {
  name: "Cases",
  components: {
    CasesTable,
  },
};
</script>
